<template>
  <div class="h-100">
    <div class="contact container">
      <div class="row h-100 contact__row">
        <div class="col-12 col-md-6 d-flex">
          <div class="title d-flex align-items-center pb-4 position-relative">
            <span class="backButton">
              <a @click="$router.go(-1)">
                <i class="fa fa-chevron-left"></i>
              </a>
            </span>
            <div class="d-flex flex-column align-self-center pt-3 pt-md-0">
              <h4>Gegevens voorzitter</h4>

              <p class="text-second">
                Vul de gegevens van de voorzitter in zodat we een afspraak kunnen
                maken.
              </p>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 mh-100 d-flex justify-content-center">
          <form class="w-100 form d-flex justify-content-center flex-column">
            <div class="form__item d-flex flex-column mb-md-4">
              <label for="name"> Naam voorzitter </label>
              <input
                v-model="contactForm.name"
                v-validate="'required|min:3'"
                name="name"
                id="name"
                type="text"
                placeholder="Voor- en achternaam"
              />
              <p class="help is-danger" v-show="errors.has('name')">
                {{ errors.first('name') }}
              </p>
            </div>

            <div class="form__item d-flex flex-column mb-md-4">
              <label for="tel"> Telefoonnummer voorzitter </label>
              <input
                v-model="contactForm.tel"
                v-validate="{
                  required: true,
                  regex: /^((\+|00)?31|0(?!0))(\d{9})$/,
                }"
                name="tel"
                id="tel"
                type="tel"
                placeholder="0612345678"
              />
              <p class="help is-danger" v-show="errors.has('tel')">
                {{ errors.first('tel') }}
              </p>
            </div>

            <div class="form__item d-flex flex-column mb-md-4">
              <label for="mail"> Mailadres voorzitter </label>
              <input
                v-model="contactForm.mail"
                v-validate="'required|email'"
                name="mail"
                id="mail"
                type="email"
                placeholder="voorzitter@vvodin.nl"
              />
              <p class="help is-danger" v-show="errors.has('mail')">
                {{ errors.first('mail') }}
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>

      <nextButton
        v-on:click.native="submitForm"
        :nextStep="nextStep"
        :className="!errors.any() && pass ? '' : 'disabled'"
        class="mt-auto w-100"
      ></nextButton>

      <errorModal
        v-if="error"
        :whatsappMSG="whatsappMSG"
        :errorTitle="errorTitle"
        :errorMsg="errorMsg"
      ></errorModal>
  </div>
</template>

<script>
import nextButton from '@/components/next_button.vue';
import errorModal from '@/components/errorModal.vue';

import { mapGetters } from 'vuex';

import { executeApiCall } from '@/apiClient';

export default {
  name: 'contacts',
  components: {
    nextButton,
    errorModal,
  },
  data() {
    return {
      nextStep: '/thnx',
      pass: false,
      contactForm: {
        name: '',
        tel: '',
        mail: ''
      },
      errorTitle: 'Geen gegevens gevonden',
      errorMsg: 'Probeer het nog een keer of stuur een bericht naar onze klanten service.',
      whatsappMSG: 'Hoi! Ik kan het contact form van de clubactie niet laden. Kunnen jullie me hierbij helpen?',
      error: false,
    };
  },
  watch: {
    contactFormWatcher(newState, oldState) {
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(this.contactForm)) {
        if (value == '') {
          return;
        }
      }

      this.pass = true;
    },
  },

  methods: {
    async submitForm() {
      if (this.getClubData) {
        if (!this.errors.any() && this.pass) {
          this.getCurrentReservation.contactForm = this.contactForm;

          const res = await executeApiCall(
            'post',
            '/setDate',
            this.getCurrentReservation
          );

          if (!res?.ok) {
            this.error = true;
          }
        }
      } else {
        this.error = true;
      }
    },
  },

  computed: {
    contactFormWatcher() {
      console.log('contactFormWatcher');
      return JSON.stringify(this.contactForm);
    },

    currentReservationId() {
      return this.getCurrentReservationId;
    },

    ...mapGetters(['getClubData', 'getCurrentReservation']),
  },
};
</script>
